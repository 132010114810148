.title_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.title_text {
  margin-top: 1rem;
}

.info_container {
  display: flex;
  align-items: center;
  font-size: 0.9rem !important;
  color: #2b2c34 !important;
}

.desc_container {
  display: flex;
  font-size: 0.9rem !important;
  color: #2b2c34 !important;
}

.icons {
  width: 20px;
  margin: 0px 10px 15px 0px;
}

/* .pd-b {
  padding-bottom: 1rem;
} */

p {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.desc_box {
  width: 250px;
  max-height: 72px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  margin-left: 0.8rem !important;
}

.pop-up-btn {
  margin: 0 20px 0 0;
  position: relative;
  z-index: 1;
  transform: translateY(10px);
}

.wave-container {
  height: 5rem;
}

.wave-img {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.text_container {
  margin-left: 0.8rem !important;
  /* margin-bottom: 0.5rem !important;  */
}
