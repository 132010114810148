/* html,
body {
  min-height: 100%;
}
body {
  position: relative;
} */

.search__results {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-top: -1.3rem;
  font-size: 1.4rem !important;
  font-weight: 500;
}

/* 
.secondary__title {
  font-weight: 400;
  font-size: 18px;
}
*/
.container {
  width: 100%;
  height: 73vh;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.8rem;
  /* overflow: hidden; */
}

.filter__results {
  min-width: 262px;
  width: 17vw;
  height: 100%;
  padding: 20px 25px;
  margin-right: 10px;
  background: #e5e5f3;
  border-radius: 17px;
}

.results {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  flex-wrap: wrap;
}

.bottom-con {
  display: flex;
  align-items: center;
}

.tertiary__title {
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.9rem;
}

.secondary__title {
  font-size: 1.2rem;
}

.row {
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.input {
  width: 8.5rem;
  margin-left: 1.5rem;
  padding: 0.4rem;
  /* border-radius: 10px; */
  text-align: center;
}

.filter-icon-container {
  display: none;
}

select {
  border: none;
}

select:focus {
  border: none;
  outline: none;
}

.pd-b {
  padding-bottom: 10px;
}

.pd-r {
  padding-right: 20px;
}

.css-17pvh7z-MuiAutocomplete-root .MuiOutlinedInput-root {
  margin: 4px 0;
}

@media screen and (max-width: 575px) {
  .results::-webkit-scrollbar {
    display: none;
  }

  .filter__results {
    display: none;
  }

  .filter-icon-container {
    display: initial;
    margin-left: 1rem;
  }

  .filter-icon {
    transform: translateY(0.15rem);
  }

  .filter-icon:hover {
    cursor: pointer;
  }

  .title {
    font-size: 1.2rem !important;
    padding-left: 1.5rem;
  }

  .container {
    padding-left: 1rem;
  }

  .filter-text {
    font-size: 0.8rem;
    color: rgba(10, 10, 10, 0.61);
  }
}
