@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  /* background-color: rgb(158, 156, 156); */
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  /* background-color: #343a49; */
  /* border-radius: 20px; */
  width: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(158, 156, 156);
}

::-webkit-scrollbar-thumb {
  background-color: #343a49;
  border-radius: 20px;
  width: 10px;
}

img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  height: inherit;
  width: inherit;
}

a {
  text-decoration: none;
}

.wrapper-container {
  width: 32vw !important;
  /* min-width: 20rem; */
  float: left;
  margin-left: 8rem !important;
  padding: 10px 22px 20px;
  border-radius: 10px;
  background: #dfdff0;
}

.title {
  font-family: Poppins, "sans-serif" !important;
  font-size: 1.5rem !important;
  color: #2b2c34 !important;
  padding-top: 0.2rem;
  margin-bottom: 0.4rem !important;
  font-weight: 550 !important;
  letter-spacing: 0.02rem !important;
}

.stack-item {
  margin: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.textfieldHead {
  font-size: 0.8rem !important;
  font-family: Poppins, "sans-serif" !important;
  color: #2b2c34 !important;
  font-weight: 450 !important;
  width: 10rem !important;
  padding-top: 0.5rem !important;
}

input {
  border: none;
  background-color: #fff;
  width: 100%;
  height: auto;
}

input:focus {
  outline: none;
}

p {
  margin-right: 0rem !important;
  margin-bottom: 0.5rem;
}

.btns-wrapper {
  display: flex;
  justify-content: flex-end;
}

.add-req-btn {
  width: 8rem !important;
  display: inline-block !important;
  background-color: #001963 !important;
  color: #fff !important;
  text-transform: inherit !important;
  margin: 0.5rem !important;
  border-radius: 0.5em !important;
}

.draft-btn {
  width: 6rem !important;
  display: inline-block !important;
  background-color: #fff !important;
  color: #2b2c34 !important;
  text-transform: inherit !important;
  margin: 0.5rem !important;
  border-radius: 0.5em !important;
}

.num-input {
  padding-left: 20px;
  width: 185px;
  height: 2.4rem;
  margin-top: 0.4rem;
  border: none;
}

.text__icon {
  width: 35px;
  height: 35px;
  margin-top: 0.5rem;
  border: none;
  background-color: #dfdff0 !important;
  cursor: pointer;
}

.description-field {
  width: 270px !important;
  height: 8rem !important;
  border-radius: 0.2rem;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.date-wrap {
  background-color: #fff;
  display: flex;
  align-items: center;
}
.icons {
  width: 1.3rem;
  transform: translateY(8px);
  margin-left: 0.7rem !important;
  margin-right: 0.5rem !important;
}
.location-wrap {
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 62%;
}
.pd-l {
  padding-left: 2.8rem;
}

.no-text-wrap {
  white-space: nowrap;
}

.css-1p5q5e5-MuiStack-root > :not(style) + :not(style) {
  margin-top: 2rem !important;
}

/*Confirm alert popUp*/

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
}
.yesButton {
  background-color: #001963 !important;
  margin: 0 15px !important;
}

.noButton {
  border: 1px solid #001963 !important;
  color: #001963 !important;
  background-color: transparent !important;
}

.react-confirm-alert-body {
  font-weight: 500 !important;
  color: black !important;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}
/* ////////// */

@media screen and (max-width: 750px) {
  .bg-container {
    display: none;
  }
  .wave {
    display: none;
  }
  .wrapper-container {
    margin: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .wrapper-container {
    width: 19rem !important;
    height: 560px;
    margin-bottom: 2rem !important;
    float: none;
  }

  .textfieldHead {
    width: 7rem !important;
    padding-right: 10px !important;
  }
  .input-field {
    width: 50% !important;
    height: auto !important;
  }
  .location-wrap {
    width: 50%;
  }
  .num-input {
    width: 70px;
  }
  .description-field {
    width: 132px !important;
    height: 5rem !important;
    border-radius: 0.2rem;
  }
  .btns-wrapper {
    justify-content: center;
  }
  .add-req-btn {
    width: 7rem !important;
  }
  .img-placeholder {
    width: 1.1rem;
    height: auto;
  }
  .date-picker {
    width: 130px;
    padding: 0.4rem;
    font-size: 0.9rem;
    border: none;
  }
  .dataItem {
    font-size: 0.9rem;
    padding-left: 1.5rem;
  }
  .react-confirm-alert-body {
    max-width: 300px;
  }
  .hide {
    display: none;
  }
  .pd-l {
    padding-left: 0.8rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .wrapper-container {
    width: 22rem !important;
    height: 560px;
    margin-left: 2rem !important;
    margin-bottom: 2rem !important;
    float: none;
  }
  .bg-container {
    position: absolute;
    right: -2rem;
  }

  .textfieldHead {
    width: 7rem !important;
    padding-right: 10px !important;
  }
  .input-field {
    width: 60% !important;
    height: auto !important;
  }
  .location-wrap {
    width: 60%;
  }
  .num-input {
    width: 110px;
  }
  .description-field {
    width: 175px !important;
    height: 5rem !important;
    border-radius: 0.2rem;
  }
  .date-picker {
    width: 135px;
    padding: 0.4rem;
    font-size: 0.9rem;
    border: none;
  }
  .dataResult-source {
    margin-left: 36.8%;
    margin-top: 8.4rem;
    width: 58.5%;
  }
  .dataResult-destination {
    margin-left: 36.8%;
    margin-top: 8.4rem;
    width: 58.5%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .wrapper-container {
    width: 25rem !important;
    height: 560px;
    margin-left: 6rem !important;
    margin-bottom: 2rem !important;
    float: none;
  }
  .bg-container {
    position: absolute;
    right: -2rem;
  }

  .textfieldHead {
    width: 7rem !important;
    padding-right: 10px !important;
  }
  .input-field {
    width: 60% !important;
    height: auto !important;
  }
  .location-wrap {
    width: 60%;
  }
  .num-input {
    width: 140px;
  }
  .description-field {
    width: 206px !important;
    height: 5rem !important;
    border-radius: 0.2rem;
  }
  .date-picker {
    width: 165px;
    padding: 0.4rem;
    font-size: 0.9rem;
    border: none;
  }
  .dataResult-source {
    margin-left: 31.8%;
    margin-top: 8.4rem;
    width: 59%;
  }
  .dataResult-destination {
    margin-left: 31.8%;
    margin-top: 8.4rem;
    width: 59%;
  }
}

@media screen and (min-width: 1440px) {
  .wrapper-container {
    height: 560px;
  }
  .input-field {
    width: 62% !important;
    height: auto !important;
  }

  .num-input {
    width: 200px;
  }
  .description-field {
    height: 6rem !important;
    border-radius: 0.2rem;
  }

  .date-picker {
    width: 230px;
    padding: 0.4rem;
    font-size: 0.9rem;
    border: none;
  }
}

.navContainer {
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navMenu {
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

a {
  color: #2b2c34;
}

.tcanchor {
  font-weight: bold;
}

.tcanchor:hover {
  color: #505ca9;
}

.Chat {
  flex: 0.65 1;
  display: flex;
  flex-direction: column;
}
.card__container {
  min-width: 250px;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #e5e5f3;
  padding: 10px 15px 12px;
  border-radius: 10px;
  margin: 0 10px 20px;
  font-size: 14px;
}

button[disabled] {
  background: rgb(163, 159, 159);
}

.user__icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.user__icon > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user__name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card__icon > img {
  width: 15px;
  height: 15px;
}

.user__data {
  margin: 10px 0;
  line-height: 2rem;
}

.user__icon,
.card__icon {
  padding-right: 8px;
}

.desc__btn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.send__container {
  margin-top: 0px;
  display: flex;
  justify-content: flex-end;
}

.send-btn {
  border-radius: 10px;
  padding: 8px 17px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 11px;
}

.blue__btn {
  color: white;
  background-color: #001963;
}

.card__container:hover {
  cursor: pointer;
}
/* rgb(229,229,243) */

.title_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.title_text {
  margin-top: 1rem;
}

.info_container {
  display: flex;
  align-items: center;
  font-size: 0.9rem !important;
  color: #2b2c34 !important;
}

.desc_container {
  display: flex;
  font-size: 0.9rem !important;
  color: #2b2c34 !important;
}

.icons {
  width: 20px;
  margin: 0px 10px 15px 0px;
}

/* .pd-b {
  padding-bottom: 1rem;
} */

p {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.desc_box {
  width: 250px;
  max-height: 72px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  margin-left: 0.8rem !important;
}

.pop-up-btn {
  margin: 0 20px 0 0;
  position: relative;
  z-index: 1;
  transform: translateY(10px);
}

.wave-container {
  height: 5rem;
}

.wave-img {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.text_container {
  margin-left: 0.8rem !important;
  /* margin-bottom: 0.5rem !important;  */
}

hr {
  border: none;
  border-top: 1px dashed #000000;
  color: #000000;

  height: 1px;
  border-radius: 50%;
}

.dashCardContainer {
  background-color: #e5e5f3;
  min-width: 315px;
  margin: 10px;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
}
.top-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}
.dashCity {
  font-size: 1.2rem;
  margin: 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
}
.srcCity {
  max-width: 100px;
}
.desCity {
  max-width: 115px;
}
.dashDeleteIcon {
  position: absolute;
  right: 25px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.date-container {
  font-size: 1rem;
  margin: 10px 10px 0;
}
.nop-container {
  font-size: 1rem;
  margin: 5px 10px 0;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
}
.yesButton {
  background-color: #001963 !important;
  margin: 0 15px !important;
}

.noButton {
  border: 1px solid #001963 !important;
  color: #001963 !important;
  background-color: transparent !important;
}

.react-confirm-alert-body {
  font-weight: 500 !important;
  color: black !important;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

@media screen and (max-width: 900px) {
  .dashCardContainer {
    margin: 10px auto;
  }
}

/* html,
body {
  min-height: 100%;
}
body {
  position: relative;
} */

.search__results {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-top: -1.3rem;
  font-size: 1.4rem !important;
  font-weight: 500;
}

/* 
.secondary__title {
  font-weight: 400;
  font-size: 18px;
}
*/
.container {
  width: 100%;
  height: 73vh;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.8rem;
  /* overflow: hidden; */
}

.filter__results {
  min-width: 262px;
  width: 17vw;
  height: 100%;
  padding: 20px 25px;
  margin-right: 10px;
  background: #e5e5f3;
  border-radius: 17px;
}

.results {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  flex-wrap: wrap;
}

.bottom-con {
  display: flex;
  align-items: center;
}

.tertiary__title {
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.9rem;
}

.secondary__title {
  font-size: 1.2rem;
}

.row {
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.input {
  width: 8.5rem;
  margin-left: 1.5rem;
  padding: 0.4rem;
  /* border-radius: 10px; */
  text-align: center;
}

.filter-icon-container {
  display: none;
}

select {
  border: none;
}

select:focus {
  border: none;
  outline: none;
}

.pd-b {
  padding-bottom: 10px;
}

.pd-r {
  padding-right: 20px;
}

.css-17pvh7z-MuiAutocomplete-root .MuiOutlinedInput-root {
  margin: 4px 0;
}

@media screen and (max-width: 575px) {
  .results::-webkit-scrollbar {
    display: none;
  }

  .filter__results {
    display: none;
  }

  .filter-icon-container {
    display: inline;
    display: initial;
    margin-left: 1rem;
  }

  .filter-icon {
    transform: translateY(0.15rem);
  }

  .filter-icon:hover {
    cursor: pointer;
  }

  .title {
    font-size: 1.2rem !important;
    padding-left: 1.5rem;
  }

  .container {
    padding-left: 1rem;
  }

  .filter-text {
    font-size: 0.8rem;
    color: rgba(10, 10, 10, 0.61);
  }
}

.filter__results_nav {
  min-width: 262px;
  width: 17vw;
  height: 100%;
  padding: 20px 25px;
  margin-right: 10px;
  background: #e5e5f3;
  /* border-radius: 17px; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.close-icon {
  float: right;
  padding-top: 5px;
  cursor: pointer;
}

.row-flex {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 575px) {
  .overlay {
    display: none;
  }
}

.textfieldHead {
  font-size: 1rem;
  letter-spacing: 0.03em;
  color: #2b2c34;
  margin-bottom: 0.8rem;
}

.redText {
  color: #e45858;
}

.mr {
  /* margin-right: 15px !important; */
}

.pd-b {
  padding-bottom: 2px !important;
}

.location-container {
  width: 100%;
}

.crop {
  max-height: 220px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.crop img {
  max-height: none;
  max-height: initial;
}
.dashBg-container {
  max-width: 40vw;
  z-index: 5;
  position: absolute;
  right: 8rem;
  margin-top: -0.7rem;
}
.active-text {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 550;
  color: #001963;
  margin-left: 0.8rem;
}
.activeOffersContainer {
  float: left;
  padding-top: 1rem;
  /* background-color: rgb(241, 141, 141); */
  margin-right: 1.5rem;
  max-width: 1040px;
}
.activeOffersCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  max-height: 310px;
  overflow-y: auto;
}

.profile {
  /* background-color: rgb(233, 135, 135); */
  float: left;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-img-container {
  position: absolute;
  margin-top: -9rem;
  /* smargin-left: 5rem; */
  z-index: 5;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /* border: 5px solid white; */
  position: relative;
  overflow: hidden;
}
.profile-img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.profile-name {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: #010a58;
  font-weight: 800;
  margin-top: 1rem;
}
.profile-email {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #010a58;
  font-weight: 500;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.noActiveText {
  margin-left: 1rem;
}
/* media queries for illustration svg */
@media screen and (max-width: 1530px) {
  .activeOffersContainer {
    width: 60%;
  }
}
@media screen and (max-width: 1440px) {
  .dashBg-container {
    margin-top: 0.3rem;
  }
}
@media screen and (max-width: 1300px) {
  .dashBg-container {
    margin-top: 1.3rem;
  }
}
@media screen and (max-width: 1200px) {
  .dashBg-container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1040px) {
  .dashBg-container {
    margin-top: 3rem;
  }
}

/* media queries for small devices */
@media screen and (max-width: 900px) {
  .dashBg-container {
    display: none;
  }
  .crop {
    display: none;
  }
  .profile {
    /* background-color: rgb(233, 135, 135); */
    float: none;
    width: 15%;
    margin: auto;
  }
  .profile-img-container {
    position: absolute;
    margin-top: 0rem;
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
  }
  .profile-name {
    font-size: 1rem;
    white-space: nowrap;
  }
  .profile-email {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }

  .activeOffersContainer {
    width: 100%;
    margin-bottom: 4rem;
  }
  .activeOffersCards {
    max-height: none;
  }
  .active-text {
    font-size: 1.2rem;
    margin-left: 10%;
  }
  .activeOffersContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.Convcontainer {
  width: 100%;
  height: 100%;
}

/* Message */
.messagesBoxContainer {
  width: 100%;
  height: 100%;
  margin: 0;
}

.messageHead {
  width: 100%;
  min-height: 76px;
  border-radius: 10px;
  background-color: #e5e5f3;
}

.messageBox {
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.messageBox::-webkit-scrollbar {
  display: none;
}

.messageInput {
  width: 100%;
  min-height: 60px;
}

/*Conversations*/
.conversationsContainer {
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.conversationsContainer::-webkit-scrollbar {
  display: none;
}

