* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  /* background-color: rgb(158, 156, 156); */
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  /* background-color: #343a49; */
  /* border-radius: 20px; */
  width: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(158, 156, 156);
}

::-webkit-scrollbar-thumb {
  background-color: #343a49;
  border-radius: 20px;
  width: 10px;
}

img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  height: inherit;
  width: inherit;
}

a {
  text-decoration: none;
}
