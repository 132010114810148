.Chat {
  flex: 0.65;
  display: flex;
  flex-direction: column;
}
.card__container {
  min-width: 250px;
  width: 250px;
  height: fit-content;
  background: #e5e5f3;
  padding: 10px 15px 12px;
  border-radius: 10px;
  margin: 0 10px 20px;
  font-size: 14px;
}

button[disabled] {
  background: rgb(163, 159, 159);
}

.user__icon {
  width: fit-content;
  height: fit-content;
}

.user__icon > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user__name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card__icon > img {
  width: 15px;
  height: 15px;
}

.user__data {
  margin: 10px 0;
  line-height: 2rem;
}

.user__icon,
.card__icon {
  padding-right: 8px;
}

.desc__btn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.send__container {
  margin-top: 0px;
  display: flex;
  justify-content: flex-end;
}

.send-btn {
  border-radius: 10px;
  padding: 8px 17px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 11px;
}

.blue__btn {
  color: white;
  background-color: #001963;
}

.card__container:hover {
  cursor: pointer;
}
/* rgb(229,229,243) */
