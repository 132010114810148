.filter__results_nav {
  min-width: 262px;
  width: 17vw;
  height: 100%;
  padding: 20px 25px;
  margin-right: 10px;
  background: #e5e5f3;
  /* border-radius: 17px; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.close-icon {
  float: right;
  padding-top: 5px;
  cursor: pointer;
}

.row-flex {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 575px) {
  .overlay {
    display: none;
  }
}
