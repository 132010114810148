@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.crop {
  max-height: 220px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.crop img {
  max-height: initial;
}
.dashBg-container {
  max-width: 40vw;
  z-index: 5;
  position: absolute;
  right: 8rem;
  margin-top: -0.7rem;
}
.active-text {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 550;
  color: #001963;
  margin-left: 0.8rem;
}
.activeOffersContainer {
  float: left;
  padding-top: 1rem;
  /* background-color: rgb(241, 141, 141); */
  margin-right: 1.5rem;
  max-width: 1040px;
}
.activeOffersCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  max-height: 310px;
  overflow-y: auto;
}

.profile {
  /* background-color: rgb(233, 135, 135); */
  float: left;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-img-container {
  position: absolute;
  margin-top: -9rem;
  /* smargin-left: 5rem; */
  z-index: 5;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /* border: 5px solid white; */
  position: relative;
  overflow: hidden;
}
.profile-img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.profile-name {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: #010a58;
  font-weight: 800;
  margin-top: 1rem;
}
.profile-email {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #010a58;
  font-weight: 500;
  margin-top: 0.5rem;
  letter-spacing: 1px;
}

.noActiveText {
  margin-left: 1rem;
}
/* media queries for illustration svg */
@media screen and (max-width: 1530px) {
  .activeOffersContainer {
    width: 60%;
  }
}
@media screen and (max-width: 1440px) {
  .dashBg-container {
    margin-top: 0.3rem;
  }
}
@media screen and (max-width: 1300px) {
  .dashBg-container {
    margin-top: 1.3rem;
  }
}
@media screen and (max-width: 1200px) {
  .dashBg-container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1040px) {
  .dashBg-container {
    margin-top: 3rem;
  }
}

/* media queries for small devices */
@media screen and (max-width: 900px) {
  .dashBg-container {
    display: none;
  }
  .crop {
    display: none;
  }
  .profile {
    /* background-color: rgb(233, 135, 135); */
    float: none;
    width: 15%;
    margin: auto;
  }
  .profile-img-container {
    position: absolute;
    margin-top: 0rem;
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
  }
  .profile-name {
    font-size: 1rem;
    white-space: nowrap;
  }
  .profile-email {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }

  .activeOffersContainer {
    width: 100%;
    margin-bottom: 4rem;
  }
  .activeOffersCards {
    max-height: none;
  }
  .active-text {
    font-size: 1.2rem;
    margin-left: 10%;
  }
  .activeOffersContainer::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
