.Convcontainer {
  width: 100%;
  height: 100%;
}

/* Message */
.messagesBoxContainer {
  width: 100%;
  height: 100%;
  margin: 0;
}

.messageHead {
  width: 100%;
  min-height: 76px;
  border-radius: 10px;
  background-color: #e5e5f3;
}

.messageBox {
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.messageBox::-webkit-scrollbar {
  display: none;
}

.messageInput {
  width: 100%;
  min-height: 60px;
}

/*Conversations*/
.conversationsContainer {
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.conversationsContainer::-webkit-scrollbar {
  display: none;
}
